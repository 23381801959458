import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { CloseIcon, CreateSurveyInfo, EmptyIcon,QuestionCard, HeaderContainer, QuestionIconContainer, QuestionText, StyledBody, StyledModal } from "./style";
import { withRouter } from "react-router-dom";
import {
  likertScaleIcon,
  mcqIcon,
  opinionScaleIcon,
  shortDescIcon,
  yesnoIcon,
} from "../../../utils/icons";



class QuestionReorderPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      showModal,
      headerText,
      onClose,
      questions,
      onDragStart,
      onDragOver,
      onDrop,
    } = this.props;

    return (
      <StyledModal
        show={showModal}
        onHide={onClose}
        dialogClassName={"info-popup"}
        backdropClassName="drop-back"
        style={{ top: "-20%" }}
      >
        <Modal.Header>
          <HeaderContainer>
            <div>
              <span>{headerText}</span>
            </div>
            <CloseIcon
              src="/public/images/new_close_icon.png"
              onClick={onClose}
            />
          </HeaderContainer>
        </Modal.Header>
        <StyledBody>
          <CreateSurveyInfo
            style={{ padding: "25px", height: "485px", overflowY: "scroll" }}
          >
            {questions.map((que, index) => 
              <QuestionCard
                draggable
                onDragStart={(event) => onDragStart(event, index)}
                onDragOver={onDragOver}
                onDrop={(event) => onDrop(event, index)}
                key={index}
              >
                <QuestionIconContainer>
                  {que.questionType.name === "short-answer" ? (
                    shortDescIcon()
                  ) : que.questionType.name === "opinion-scale" ? (
                    opinionScaleIcon()
                  ) : que.questionType.name === "multiple-choice" ? (
                    mcqIcon()
                  ) : que.questionType.name === "yes-no" ? (
                    yesnoIcon()
                  ) : que.questionType.name === "likert-scale" ? (
                    likertScaleIcon()
                  ) : (
                    <EmptyIcon />
                  )}
                </QuestionIconContainer>
                <div style={{ marginLeft: "13px" }}>
                  <QuestionText>{que.question}</QuestionText>
                </div>
              </QuestionCard>
            )}
          </CreateSurveyInfo>
        </StyledBody>
      </StyledModal>
    );
  }
}

QuestionReorderPopup.defaultProps = {
  challenge: { name: "", duration: "", body: "" },
};

QuestionReorderPopup.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  onNoClick: PropTypes.func,
  onYesClick: PropTypes.func,
  deleteref: PropTypes.string,
  headerText: PropTypes.string,
  history: PropTypes.object,
  updateStep: PropTypes.func,
  questions: PropTypes.array,
  onDragStart: PropTypes.func,
  onDragOver: PropTypes.func,
  onDrop: PropTypes.func,
  suvreyId: PropTypes.number,
  companyId: PropTypes.number,
};

export default withRouter(
  connect(null, null)(withTranslation()(QuestionReorderPopup))
);