import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { StyledTextArea } from "../../CreateEvent/style";



const DynamicStyledTextArea = ({ placeholder, name, onChange, value, maxLength, index }) => {
  const textareaRef = useRef(null);

  const handleInputChange = (e) => {
    onChange(index, e); 

    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto"; 
      textarea.style.height = `${textarea.scrollHeight}px`; 
    }
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "48px"; 
      textarea.style.height = `${textarea.scrollHeight}px`; 
    }
  }, [value]); 

  return (
    <StyledTextArea
      ref={textareaRef}
      placeholder={placeholder}
      name={name}
      onChange={handleInputChange}
      value={value}
      maxLength={maxLength}
      style={{ height: "auto" }} 
    />
  );
};

DynamicStyledTextArea.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  isExpanded: PropTypes.bool,
  rows: PropTypes.number,
  index: PropTypes.number.isRequired, 
};

export default DynamicStyledTextArea;